.seasonalAndSpecialsSection {
    background-image: url('../../assets/images/banner_1.png');
    background-position: center;
    background-size: cover;
    margin: 0;
    padding: 2rem 2rem 4rem 2rem;
    color: white;
}

.seasonalAndSpecialsContainer {
    background-image: linear-gradient(to bottom right, rgba(101, 101, 101, 0.95), rgba(168, 168, 168, 0.8));
    border: 5px dashed black;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 1rem;
}

.seasonalAndSpecialsItemContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    width: 100%;
    box-sizing: border-box;
}

.seasonalAndSpecialsCarousel {
    display: none;
}

@media screen and (max-width: 65rem) {
    .seasonalAndSpecialsSection {
        background-image: url('../../assets/images/mural_vertical.jpg');
        padding: 0;
    }

    .seasonalAndSpecialsItemContainer {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        width: 100%;
    }
}

@media screen and (max-width: 45rem) {

    .seasonalAndSpecialsContainer {
        background-image: linear-gradient(to bottom right, rgba(62, 62, 62, 0.95), rgba(168, 168, 168, 0.8));
        border: 5px dashed black;

        padding: 1rem 0 4rem 0;
    }

    .seasonalAndSpecialsItemContainer {
        display: none;
    }

    .seasonalAndSpecialsCarousel {
        display: block;
    }
}