.behindTheScenesContainer {
    background-image: url("../../assets/images/paper_texture_1.jpg");
    background-position: center;
    background-size: cover;
    height: fit-content;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    padding: 4rem 2rem;

    margin: -2rem 0;

    clip-path: polygon(
    0% 0%, 3% 0%, 7% 1%, 11% 0%, 16% 2%, 20% 0%, 23% 2%, 28% 2%, 32% 1%, 35% 1%, 
    39% 3%, 41% 1%, 45% 0%, 47% 2%, 50% 2%, 53% 0%, 58% 2%, 60% 2%, 63% 1%, 65% 0%, 
    67% 2%, 69% 2%, 73% 1%, 76% 1%, 79% 0%, 82% 1%, 85% 0%, 87% 1%, 89% 0%, 92% 1%, 
    96% 0%, 100% 0%, 100% 100%, 96% 100%, 92% 99%, 89% 100%, 86% 99%, 83% 100%, 77% 99%, 
    72% 100%, 66% 98%, 62% 100%, 59% 99%, 54% 99%, 49% 100%, 46% 98%, 43% 100%, 40% 98%, 
    38% 100%, 35% 99%, 31% 100%, 28% 99%, 25% 99%, 22% 100%, 19% 99%, 16% 100%, 13% 99%, 
    10% 99%, 7% 100%, 4% 99%, 0% 100%, 0% 0%);

}

.behindTheScenesItem {
    width: 90%;
    text-align: center;
}

.behindTheScenesVideo {
    width: 100%;
}

@media screen and (max-width: 65rem) {
    .behindTheScenesContainer {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        padding: 4rem 0rem;

        /* margin: 0; */
    }
}