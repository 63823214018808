.aboutPage {
    min-height: fit-content;
    width: 100vw;
    min-width: fit-content;
    padding: 7rem;
    
    /* Grid */
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    box-sizing:border-box;
}

.aboutTextSection {
    padding: 2rem;
}

.aboutImageSection {
    display: grid;
    align-items: center;
    padding: 2rem;
}

@media screen and (max-width: 80rem) {
    .aboutPage {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 45rem) {
    .aboutPage {
        padding: 7rem 0 0 0;
    }
}