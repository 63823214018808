.menuPage {
    text-align: center;
    background-color: #a22015;
    padding: 8rem 0 0 0;
}

.menuImage {
    width: 90%;
    max-width: 85rem;
    margin: 2rem auto;
}