.footerSection {
    background-color: black;
    height: fit-content;
    color: white;
    padding: 2rem 0;
}

.footerSectionContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    
    padding: 2rem;
    text-align: center;
}

.footerSection li {
    list-style: none;
}

.mapStyles {
    width: 30rem;
    height: 20rem;
}

.allRightsReservedParagraph {
    margin: 0 auto;
    text-align: center;
}

.companyName {
    margin: 0.5rem auto;
    text-align: center;
}

.companyNameLink {
    color: white;
}

@media screen and (max-width: 70rem) {
    .footerSectionContainer {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 45rem) {
    .footerSectionContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }

    .mapStyles {
        width: 80vw;
        margin: 0;
    }

    .allRightsReservedParagraph {
        margin: 0 auto;
        text-align: center;
        font-size: 1rem;
    }
}