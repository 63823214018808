.baseButton {
    color: white;
    width: fit-content;
    padding: 15px 40px;
    border-radius: 100px;
    display: inline-block;
    border: 2px solid white;
}

.baseButton:hover {
    cursor: pointer;
}

.baseButton p {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.2rem;
}