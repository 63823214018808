.constructionSection {
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: relative;
}

.constructionElement {
    width: 100vw;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.constructionElement img {
    width: 20rem;
    margin: 0;
}