.cateringPage {
    width: 100%;
    min-height: fit-content;
    height: 100vh;
    background-color: #a22015;
    padding: 10rem 0 5rem 0;
}

.floatingCard {
    background-color: white;
    text-align: center;

    /* Floating card size */
    width: 60rem;
    height: fit-content;
    padding: 1rem;

    /* Centralize form container */
    margin: auto;

    /* Shadow */
    box-shadow: 15px 15px 0px 0 rgba(0, 0, 0, 1); /* Add black box shadow */
}

.innerContainer {
    border: 4px dashed black;
}

.cateringMenuHeading {
    font-size: 3rem;
    font-family: "Neucha", cursive;
    font-weight: 600;
    font-style: normal;
    margin: 0;
}

.calculatorContent {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
}

.calculatorSection {
    width: 40rem;
}

.calculatorForm {
    margin: 1rem 0;

    /* Font */
    font-size: 1.6rem;
    font-family: "Neucha", cursive;
    font-weight: 400;
    font-style: normal;
}

.calculatorForm input, select {
    width: 4rem;
    margin: 0rem 1rem;

    /* Font */
    font-size: 1.3rem;
    font-family: "Neucha", cursive;
    font-weight: 400;
    font-style: normal;
}

.mediaSection {
    text-align: center;
}

.tacoBarImage {
    width: 28rem;
    border: 4px dashed black;
    margin: 1rem 0;
}

.cateringButton {
    margin: 1rem;
}



.horizontalLine {
    height: 2px;
    background-color: black;
}

.cateringOptionsContainer {
    width: 95%;
    margin: 0rem auto;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    /* border: 1px solid red; */
}

.cateringOption {
    text-align: left;
    font-size: 1.6rem;
    font-family: "Neucha", cursive;
    font-weight: 400;
    font-style: normal;
    /* border: 1px solid green; */
}

.phoneNumberContainer {
    position: relative;
}

.phoneLink {
    text-decoration: none;
    font-weight: 600;
    color: black;
}

.phoneNumberDiv {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    text-align: center;
}

.imageContainer {
    width: 100%;
    height: 18rem;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: 50% 20%; /* Centers the image within the container */
}

/* ----- Media Queries ----- */
@media screen and (max-width: 75rem) {
    .cateringPage {
        height: auto;
        padding: 10rem 0 5rem 0;
    }

    .calculatorContent {
        grid-template-columns: repeat(1, 1fr);
    }

    .floatingCard {
        width: fit-content;
        /* height: max-content; */
    }

    .tacoBarImage {
        width: 35rem;
    }
}

@media screen and (max-width: 45rem) {
    .cateringPage {
        padding: 0;
    }

    .floatingCard {
        width: 100%;
        box-sizing: border-box;
        padding: 9rem 1rem 1rem 1rem;
    
        /* Shadow */
        box-shadow: none;
    }

    .cateringMenuHeading {
        font-size: 2.3rem;
        margin: 0.7rem 0;
    }

    .cateringOptionsContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .optionOne {
        grid-row: 1 / 2;
    }

    .optionThree {
        grid-row: 2 / 3;
    }

    .optionFive {
        grid-row: 3 / 4;
    }

    .phoneNumberContainer {
        height: 10rem;
    }

    .calculatorSection {
        width: 100%;
    }

    .tacoBarImage {
        width: 90%;
    }
}