.floatingCard {
    width: 18rem;
    height: 22rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #f1f1f1;
    padding: 0.5rem;
}

.starContainer {
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
}

.starImageContainer {
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.starImageContainer img {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}