.customerReviewsSection {
    height: fit-content;
    padding: 4rem 2rem 2rem 2rem;
}

.customerReviewsContainer {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
}

.customerReviewsContainerMobile {
    display: none;
}

@media screen and (max-width: 85rem) {
    .customerReviewsContainer {
        margin: 1rem auto;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;
        width: fit-content;
    }
}

@media screen and (max-width: 45rem) {
    .customerReviewsContainer {
        display: none;
    }

    .customerReviewsContainerMobile {
        display: block;
    }
}