.banner {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 0, 0, 0));
}

.bannerVideo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.bannerVideoContent {
    height: 100%;
    width: 100%;
    object-fit: cover;
    -webkit-filter: blur(3px);
}


.bannerContentContainer {
    width: 100vw;
    margin: auto;
    text-align: center;

    
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%, 0);
}

.bannerLogo {
    max-width: 50rem;
    width: 100vw;
}

.bannerHeading {
    color: white;
    font-size: 4rem;
    font-family: "Neucha", cursive;
    font-weight: 600;
    font-style: normal;

    margin: -1.5rem 1rem 1rem 1rem;
}

/* ---------- Media Queries -------- */
@media screen and (max-width: 50rem) {
    .banner {
        height: 50rem;
    }
    
    .bannerVideo {
        height: 50rem;
    }
}