.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(14, 14, 14, 0.7);
    z-index: 2000;
}

.window {
    position: fixed;
    width: 50rem;
    height: min-content;
    min-height: 25rem;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 7px;
    overflow: hidden;
}

.leftSection {
    text-align: center;
    padding: 0.5rem;
    position: relative;
}

.leftSectionContent {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.mobileImageContainer {
    width: 90%;
    height: 12rem;
    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
    margin: 1rem auto;
    border-radius: 5px;

    display: none;

    /* Shadow effect */
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
}

.mobileImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: 20%; /* Centers the image within the */
}

.windowHeader {
    font-size: 40px;
    font-family: "Neucha", cursive;
    font-weight: 600;
    font-style: normal;
    margin: 0;
}

.paragraph {
    font-size: 1.6rem;
    font-family: "Neucha", cursive;
    font-weight: 400;
    font-style: normal;

    margin: 0.5rem 0;
}

.imageContainer {
    width: 100%;
    height: 100%;

    overflow: hidden; /* Ensure content outside the container is hidden */
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: 20%; /* Centers the image within the container */
}

/* Tablets */
@media screen and (max-width: 70rem) {

}

/* Mobile */
@media screen and (max-width: 45rem) {
    .window {
        grid-template-columns: repeat(1, 1fr);
        width: 95%;
        height: 32rem;
        padding: 1rem 0;
    }
    
    .rightSection {
        display: none;
        border: 1px solid red;
    }

    .mobileImageContainer {
        display: inline-block;
    }

    .leftSection {
        position: static;
        height: fit-content;
    }

    .leftSectionContent {
        position: static;
        top: 0%;
        transform: translateY(0%);
    }
    
    .paragraph {
        font-size: 20px;
    }
}