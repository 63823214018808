.circularItem {
    width: max-content;
    color: white;
    margin: 1rem 0;
}

.imgContainer {
    height: 15rem; 
    width: 15rem; 
    border-radius: 50%;
    border: 3px solid white;
    overflow: hidden;

    /* Child image adjustment */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.circularItem img {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}