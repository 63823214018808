.reviewContainer {
    width: 100%;
    min-height: fit-content;
    height: 60vh;
    background-color: #a22015;
    padding: 10rem 0 5rem 0;
}

.floatingCard {
    background-color: white;
    text-align: center;

    /* Floating card size */
    width: 60rem;
    height: 30rem;
    padding: 5rem;

    /* Centralize form container */
    margin: auto;

    /* Shadow */
    box-shadow: 15px 15px 0px 0 rgba(0, 0, 0, 1);
    /* Add black box shadow */
}

.header {
    font-size: 3rem;
    font-family: "Neucha", cursive;
    font-weight: 600;
    font-style: normal;
    margin: 0;
}

@media screen and (max-width: 75rem) {

    .reviewContainer {
        width: 100%;
        min-height: fit-content;
        height: 60vh;
        background-color: #a22015;
        padding: 10rem 0 5rem 0;
    }

    .floatingCard {
        width: 30rem;
        height: 20rem;
        padding: 3rem;
    }


}