.bestInHouseSection {
    padding: 1rem 1rem;  
}

.bestInHouseSectionDesktop {
    padding: 3rem 1rem;  
}

.bestInHouseSectionMobile {
    display: None;
}

.bestInHouseContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
}

.carouselItem {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    padding: 3rem;
}

@media screen and (max-width: 80rem) {
    .bestInHouseContainer {
        display: grid;
        width: max-content;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
        margin: auto;
        justify-items: center;
    }
}

@media screen and (max-width: 45rem) {
    .bestInHouseSectionDesktop {
        display: none;
    }

    .bestInHouseSectionMobile {
        display: block;
    }
}